import { computed, isRef, ref } from '@vue/composition-api';
import { formatDate } from '~/app/models/dates/utils';

function differenceInDays(dateLeft: Date, dateRight: Date): number {
  const left = new Date(
    dateLeft.getFullYear(),
    dateLeft.getMonth(),
    dateLeft.getDate()
  );
  const right = new Date(
    dateRight.getFullYear(),
    dateRight.getMonth(),
    dateRight.getDate()
  );
  const msPerDay = 1000 * 60 * 60 * 24;
  return Math.floor((left.getTime() - right.getTime()) / msPerDay);
}

export const ExpirationStatus = Object.freeze({
  good: 'good',
  warning: 'warning',
  danger: 'danger',
});

export type ExpirationStatusConfig = {
  [status in keyof Omit<typeof ExpirationStatus, 'good'>]: number;
};

const defaultDurationColor: ExpirationStatusConfig = {
  warning: 2,
  danger: 3,
};

export function useExpirationFormatter(
  expirationDate: MaybeRef<Date | undefined>,
  statusConfig: ExpirationStatusConfig = defaultDurationColor
) {
  if (statusConfig.warning >= statusConfig.danger)
    throw new Error(
      'The warning configuration can not be greater than or equal to the danger configuration.'
    );

  const expirationDateRef = isRef(expirationDate)
    ? expirationDate
    : ref(expirationDate);

  const daysDiffFromNow = computed(() =>
    expirationDateRef.value
      ? differenceInDays(new Date(), expirationDateRef.value as Date)
      : undefined
  );

  const formattedDuration = computed(() => {
    const diff = differenceInDays(new Date(), expirationDateRef.value as Date);
    if (diff < 1) return 'Today';
    return `${diff} day${diff > 1 ? 's' : ''}`;
  });

  const formattedDate = computed(() =>
    formatDate(expirationDateRef.value as Date, { type: 'pretty' })
  );

  const status = computed(() => {
    if (daysDiffFromNow.value === undefined) return undefined;

    const { warning, danger } = statusConfig;
    if (daysDiffFromNow.value >= danger) return ExpirationStatus.danger;

    if (daysDiffFromNow.value >= warning && daysDiffFromNow.value < danger)
      return ExpirationStatus.warning;

    return ExpirationStatus.good;
  });

  return {
    formattedDate,
    formattedDuration,
    daysDiffFromNow,
    status,
  };
}
